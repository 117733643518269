import React, {useEffect} from "react";
import { useFormik} from "formik";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";

import useSubmit from "./useSubmit";
import {useAlertContext} from "./alertContext";
import { useTheme } from "../ThemeContext";
import { contactData } from "../../constants/homeConst";

const ContactForm = () => {
  const {isLoading, response, submit} = useSubmit();
  const { onOpen } = useAlertContext();

  const {lang} = useTheme();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {firstName:'',company:'', email:'', comment:''},

    onSubmit: values => {
      submit(values);
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .required(contactData.form.required[lang]),
      company: Yup.string()
        .required(contactData.form.required[lang]),
      email: Yup.string()
        .email(contactData.form.email.error[lang])
        .required(contactData.form.required[lang]),
      type: Yup.string()
        .matches(/(hireMe|reactProject|wpProject|personalizedBudget|other)/, contactData.form.enquiry.error[lang])
        .required(contactData.form.required[lang]),
      comment: Yup.string()
        .min(25, contactData.form.coment.error[lang])
        .required(contactData.form.required[lang]),
    }),
  });

  useEffect(()=>{
    // console.log(response)
    if(response){
      onOpen(response.type, response.message);

      if(response.type ==='success'){
        formik.resetForm();
        navigate('/');
      }
    }
  },[response])


  return (
    <form onSubmit={e=>{
        e.preventDefault();
        formik.handleSubmit();
      }}
      className="contact-form"
    >
      <FormControl isInvalid={formik.errors.firstName} className="form-control">
        {/* <FormLabel htmlFor="firstName">{contactData.form.name.label[lang]}</FormLabel> */}
        <Input
          id="firstName"
          name="firstName"
          placeHolder={contactData.form.name.label[lang]}
          {...formik.getFieldProps("firstName")}
        />
        <FormErrorMessage  className="form-error">{formik.errors.firstName}</FormErrorMessage>
      </FormControl><FormControl isInvalid={formik.errors.company} className="form-control">
        {/* <FormLabel htmlFor="firstName">{contactData.form.company.label[lang]}</FormLabel> */}
        <Input
          id="company"
          name="company"
          placeHolder={contactData.form.company.label[lang]}
          {...formik.getFieldProps("company")}
        />
        <FormErrorMessage  className="form-error">{formik.errors.company}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formik.errors.email}>
        {/* <FormLabel htmlFor="email">{contactData.form.email.label[lang]}</FormLabel> */}
        <Input
          id="email"
          name="email"
          type="email"
          placeHolder={contactData.form.email.label[lang]}
          {...formik.getFieldProps("email")}
        />
        <FormErrorMessage  className="form-error">{formik.errors.email}</FormErrorMessage>
      </FormControl>
      <FormControl  isInvalid={formik.errors.type}>
        {/* <FormLabel htmlFor="type">{contactData.form.enquiry.name[lang]}</FormLabel> */}

        <Select id="type" name="type"
        {...formik.getFieldProps("type")}
        placeholder={contactData.form.enquiry.placeHolder[lang]}
        >
          {
            contactData.form.enquiry.options.map(option=>
              <option value={option.value} key={option.value} >{option.key[lang]}</option>
              )
          }
        </Select>
        <FormErrorMessage className="form-error">{formik.errors.type}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formik.errors.comment}>
        {/* <FormLabel htmlFor="comment">{contactData.form.coment.label[lang]}</FormLabel> */}
        <Textarea
          id="comment"
          name="comment"
          height={250}
          placeHolder={contactData.form.coment.label[lang]}
          {...formik.getFieldProps("comment")}
        />
        <FormErrorMessage  className="form-error" >{formik.errors.comment}</FormErrorMessage>
      </FormControl>
      <Button type="submit" id={'submit-button'} width="full"  disabled={isLoading}>
        {isLoading  ? contactData.form.button.wait[lang] : contactData.form.button.submit[lang]}
      </Button>
    </form>
  );
};

export default ContactForm;
