import { HomeData } from "../../constants/homeConst";
import { useTheme } from "../ThemeContext";
import { useNavigate } from "react-router-dom";

const Contact = () =>{
    const {lang} = useTheme();
    const navigate = useNavigate();
    
    return (
        <section className="contact-section">
            <div className="text">
                <h2>{HomeData.contact.title[lang]}</h2>
                <p>{HomeData.contact.paragraph[lang]}</p>
                <button onClick={()=>navigate('/contact')}>{HomeData.contact.button[lang]}</button>
            </div>

        </section>
    )
}

export default Contact;