import { createContext, useContext, useEffect, useState } from "react";
import { ColorsConst } from "../constants/ColorsConst";

import {convertToCamelCase} from '../library'

const ThemeContext = createContext(undefined);

const Langs = ['en','es','ca','fr','de', 'ee']

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("dark");
    const [lang, setLang] = useState("ca");

    const [colors, setColors] = useState({});
    const colorKeys= Object.keys(ColorsConst);

    const toggleTheme = ()=>{
        setTheme(theme === "light" ? "dark" : "light");
    }

    const switchColor =  (color)=>{
        return  1 ? color === "light" ? "dark" : "light" : color
    }
    
    const builColors = ()=>{
        const returner = {};
        const swtiched = switchColor(theme);

        colorKeys.map(colorKey=>{
            returner[convertToCamelCase(colorKey)] = ColorsConst[colorKey][swtiched];
            returner[convertToCamelCase(colorKey+'-i')] = ColorsConst[colorKey][theme];
        })

        return returner;
    }


    const moveLogo = ()=>{
        const logo = document.querySelector('.hero-logo');
        if(!logo) return;
        const halfWidht = logo.clientWidth/2;
        document.documentElement.style.setProperty(`--wolf-hero-translate`, halfWidht+'px');
    }

    useEffect(()=>{
        Object.keys(ColorsConst).map(colorKey=>{
            document.documentElement.style.setProperty(`--${colorKey}`, ColorsConst[colorKey][theme])
            document.documentElement.style.setProperty(`--${colorKey}-i`, ColorsConst[colorKey][theme === "light" ? "dark" : "light"])
        })

        moveLogo();
        setColors(builColors());
    },[theme])

    return (
        <ThemeContext.Provider value={{
            theme,
            toggleTheme: toggleTheme,
            langs: Langs,
            lang,
            setLang:setLang,
            colors
        }}>
        {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);


