import CertificationsCardsItem from "./CertificationsCardsItem";

import { HomeData } from "../../constants/homeConst";
import { useState } from "react";

// import js from '../assets/stack/js.png'
// import css from '../assets/stack/css.png'

const CertificationsCards = () =>{

    const [focus, setFocus] = useState('');

    const unsetFocus = ()=>{
        setFocus('');
    }

    return(
        <div className="certifications-cards-main">
            <h1 className="certifications-text title">Stack</h1>

            {HomeData.certifications.map(cer=>
                <CertificationsCardsItem card={cer} setFocus={setFocus} unsetFocus={unsetFocus}/>
            )}
            <h1 className="certifications-text">{focus}</h1>
        </div>
    )
}

export default CertificationsCards;