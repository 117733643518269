
import Header from  './components/template/Header';
import Footer from './components/template/Footer';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext';

import ContactPage from './components/contact/ContactPage';
import HomePage from './components/home/HomePage';

import { AlertProvider } from './components/contact/alertContext';
import AlertModal from './components/contact/Alert';
import { ScrollToTop } from './library';


const App = ()=>{

  return(
    <>
    <AlertProvider>
      <BrowserRouter>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <div className="header-blank"></div>

        <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/contact" element={<ContactPage />}/>
        </Routes>

      <AlertModal />
      <Footer></Footer>
      </BrowserRouter>
    </AlertProvider>
    </>
  )
}


function Root() {

  return (
    <ThemeProvider>
        <App></App>
    </ThemeProvider>
  );
}

export default Root;
