import ContactForm from './ContactForm'
import Separator from "../template/Separator";
import { contactData } from '../../constants/homeConst';
import {useTheme} from '../ThemeContext';

const ContactPage = ()=>{

    const {lang} = useTheme();
    return(
        <div className="contact-page">
            <Separator text={contactData.sectionTitle[lang]} classes={'center pt-5'}></Separator>
            <ContactForm></ContactForm>
        </div>
    )
}

export default ContactPage;