import { useTheme } from "../ThemeContext";
// import MaterialIcon from 'material-icons-react';
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'

const Switch = ({icon}) => {
  const {toggleTheme} = useTheme();

  return (
    <div onClick={toggleTheme} className="header-icon-wraper">
        {icon == "light_mode" ? <LightModeIcon/> : <DarkModeIcon/>}
        {/* <MaterialIcon icon={icon ? icon : ""}  id={"icon-theme"} /> */}
    </div>
  );
};

export default Switch;
