import { useState } from "react";
import { useTheme } from "../ThemeContext";

import MaterialIcon from 'material-icons-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const LangSelect = ()=>{
    const [dropped, setDropped] = useState(0);

    const { langs, setLang } = useTheme();

    const switchDropped = ()=>{
        setDropped(!dropped);
    }

    const {lang} = useTheme();

    const dropDown = ()=>{
        const arrow = document.getElementById('drop-down-arrow');
        arrow.classList.toggle('rotate');
        switchDropped();
    }

    const resetSelector = (lan) =>{
        dropDown();
        setLang(lan);
    }

    return(
        <div className="language-select-main">
            <div className="language-select" onClick={dropDown}>
                <h2 className="header-flag">{lang}</h2>
                <KeyboardArrowDownIcon id={'drop-down-arrow'}></KeyboardArrowDownIcon>
            </div>
            <div className={`lang-drop-down ${dropped ? 'dropped' : ''} `} id='drop-down'>
                {
                true
                ?
                    langs.map((lan, indx)=>
                        <h1 key={lan} className={`drop-down-item ${lan === lang ? 'selected' : '' }`} onClick={()=>resetSelector(lan)}>{lan}</h1>
                    )
                :""
                }
            </div>
        </div>
    )
}

export default LangSelect;