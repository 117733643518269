import avatar from '../../assets/franco2.png'

import { HomeData } from '../../constants/homeConst';
import { useTheme } from '../ThemeContext';
import { redirect } from '../../library';

const Personal = ()=>{
    const {lang} = useTheme();

    const getAge = (date)=>{
        var today = new Date();
        var birthDate = new Date(date);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return(
        <section className='personal-section'>
            <div className="text">
                <h1>{HomeData.personal.name}</h1>
                {/* {getAge(HomeData.personal.age)} -  */}
                <h2>{HomeData.personal.role[lang]}</h2>
                <p>"{HomeData.personal.description[lang]}"</p>
                <button className="personal-button" onClick={()=>redirect("https://www.francolobos.com")}>{HomeData.personal.button[lang]}</button>
            </div>
            <img src={avatar} id={"personal-image"} alt={'personal-imagen'}></img>
        </section>
    )
}

export default Personal;