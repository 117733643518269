
import { Document, Page, pdfjs} from 'react-pdf';
import mainCert from '../../assets/courses/Meta-front-end.pdf'

import CertificationsCards from './CertificationsCards';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
//   ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Certifications = () =>{

    return(
        <section className="certifications-section">
            <h1 className='certifications-header'>Meta Front-End Developer</h1>
            <Document file={mainCert} onLoadError={console.error} className="pdf-viewer"
                // renderMode="canvas"
                >
                <Page pageNumber={1}
                className="pdf-canvas"
                renderTextLayer={false}
                renderAnnotationLayer={false}
                >

                </Page>
            </Document>
            <a href="https://www.coursera.org/account/accomplishments/professional-cert/475FRDAP7ECE"
                    target='_blank' rel='noreferrer' id={'certifications-button'}>
                <button>See Certification</button>
            </a>

            <CertificationsCards></CertificationsCards>
        </section>
    )
}


export default Certifications;