import { useEffect } from "react";
import { HomeData } from "../../constants/homeConst";
import { useTheme } from "../ThemeContext";

import StyledLogo from "../template/StyledLogo";
import { useNavigate } from "react-router-dom";

const Hero = ()=>{

    const {lang} = useTheme();
    const navigate = useNavigate();

    // const moveLogo = ()=>{
    //     const logo = document.querySelector('.hero-logo');
    //     if(!logo) return;
    //     const halfWidht = logo.clientWidth/2;
    //     logo.setAttribute('transform',`translate(${halfWidht}, 0)`)
    // }

    // useEffect(()=>{
    //     moveLogo();
    // },[lang]);

    return(
        <section className="hero-section">
            <h1>{HomeData.hero.title[lang]}</h1>
            <h2>{HomeData.hero.subTitle[lang]}</h2>
            <div className="text">
                <p>{HomeData.hero.paragraph[lang]}</p>
            </div>
            <button className="hero-button" onClick={()=>navigate('/contact')}>{HomeData.hero.button[lang]}</button>
            <StyledLogo dark={1} specialClass="hero-logo"></StyledLogo>
        </section>
    )
}

export default Hero;