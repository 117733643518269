export const HomeData = {

    hero: {
        title: {
        es: "Kluger Byte",
        en: "Kluger Byte",
        ca: "Kluger Byte",
        fr: "Kluger Byte",
        de: "Kluger Byte",
        ee: "Kluger Byte"
        },
        subTitle: {
          "en": "Integral Solutions",
          "es": "Soluciones Integrales",
          "ca": "Solucions Integrals",
          "fr": "Solutions Intégrales",
          "de": "Integrierte Lösungen",
          "ee": "Integraalsed lahendused"
        },
        paragraph: {
        en: "The mission of Kluger Byte is to provide innovative and cutting-edge technological solutions that drive the success of our clients. Our values include excellence in work, creativity, adaptability, and integrity in all our interactions.",
        es: "La misión de Kluger Byte es ofrecer soluciones tecnológicas innovadoras y de vanguardia que impulsen el éxito de nuestros clientes. Nuestros valores incluyen la excelencia en el trabajo, la creatividad, la adaptabilidad y la integridad en todas nuestras interacciones.",
        ca: "La missió de Kluger Byte és oferir solucions tecnològiques innovadores i de vanguardia que impulsen l'èxit dels nostres clients. Els nostres valors inclouen l'excel·lència en el treball, la creativitat, l'adaptabilitat i la integritat en totes les nostres interaccions.",
        fr: "La mission de Kluger Byte est de fournir des solutions technologiques innovantes et de pointe qui stimulent le succès de nos clients. Nos valeurs comprennent l'excellence au travail, la créativité, l'adaptabilité et l'intégrité dans toutes nos interactions.",
        de: "Die Mission von Kluger Byte ist es, innovative und fortschrittliche technologische Lösungen anzubieten, die den Erfolg unserer Kunden vorantreiben. Unsere Werte umfassen hervorragende Arbeit, Kreativität, Anpassungsfähigkeit und Integrität in all unseren Interaktionen.",
        ee: "Kluger Byte'i missioon on pakkuda innovatiivseid ja tipptasemel tehnoloogilisi lahendusi, mis toetavad meie klientide edu. Meie väärtused hõlmavad suurepärast tööd, loovust, kohanduvust ja ausust kõigis meie suhetes."
        },
        button: {
        en: "Contact",
        es: "Contactar",
        ca: "Contactar",
        fr: "Contact",
        de: "Kontakt",
        ee: "Kontakt"
        }
    },
    sections: {
        where: {
        en: "Where are we",
        es: "Donde estamos",
        ca: "On som",
        fr: "Où sommes-nous",
        de: "Wo sind wir",
        ee: "Kus me oleme"
        },
        who: {
        en: "Who are we",
        es: "Quienes somos",
        ca: "Qui som",
        fr: "Qui sommes-nous",
        de: "Wer sind wir",
        ee: "Kes me oleme"
        },
        why: {
        en: "Why us?",
        es: "Por qué nosotros?",
        ca: "Per què nosaltres?",
        fr: "Pourquoi nous?",
        de: "Warum wir?",
        ee: "Miks meie?"
        },
        ready: {
        en: "I am ready!",
        es: "¡Me apunto!",
        ca: "Estic preparat!",
        fr: "Je suis prêt(e)!",
        de: "Ich bin bereit!",
        ee: "Olen valmis!"
        }
    },


    countries: [
        {
          id: "andorra",
          globalName: {
            en: "Andorra",
            es: "Andorra",
            ca: "Andorra",
            fr: "Andorre",
            de: "Andorra",
            ee: "Andorra"
          },
          description: {
            en: "Benefits of Andorra",
            es: "Beneficios de Andorra",
            ca: "Beneficis d'Andorra",
            fr: "Avantages d'Andorre",
            de: "Vorteile von Andorra",
            ee: "Andorra eelised"
          },
          slogan: {
            en: "The Heart of the Pyrenees",
            es: "El corazón de los Pirineos",
            ca: "El cor dels Pirineus",
            fr: "Le Cœur des Pyrénées",
            de: "Das Herz der Pyrenäen",
            ee: "Pürenee süda"
          },
          advantages: {
            en: [
              "If you are an Andorran company, B2B contracts between companies provide a low tax rate, avoiding international taxes or even bank services transfers",
              "Strategic Innovation Partnership: Collaborating with Kluger Byte means forging a strategic innovation partnership. Our team's expertise and forward-thinking approach can help you ideate, develop, and implement cutting-edge solutions that drive your company's growth, elevate competitiveness, and secure a stronger market position.",
              "As residents here, we are conveniently located for in-person meetings, providing an excellent opportunity to enhance our communication"
            ],
            es: [
              "Si eres una empresa andorrana, los contratos B2B entre empresas nos brindan una baja tasa impositiva, evitando impuestos internacionales o incluso transferencias de servicios bancarios.",
              "Colaboración Estratégica de Innovación: Colaborar con Kluger Byte implica forjar una asociación estratégica de innovación. La experiencia de nuestro equipo y enfoque progresista pueden ayudarte a idear, desarrollar e implementar soluciones de vanguardia que impulsen el crecimiento de tu empresa, aumenten la competitividad y aseguren una posición más sólida en el mercado.",
              "Como residentes aquí, estamos convenientemente ubicados para reuniones en persona, brindando una excelente oportunidad para mejorar nuestra comunicación"
            ],
            ca: [
              "Si ets una empresa andorrana, els contractes B2B entre empreses ens proporcionen un baix tipus impositiu, evitant impostos internacionals o fins i tot transferències de serveis bancaris.",
              "Col·laboració Estratègica d'Innovació: Col·laborar amb Kluger Byte implica forjar una associació estratègica d'innovació. L'experiència de l'equip i l'enfocament progressista poden ajudar-te a idear, desenvolupar i implementar solucions de vanguardia que impulsen el creixement de la teva empresa, augmentin la competitivitat i assegurin una posició més sòlida al mercat.",
              "Com a residents aquí, estem convenientment ubicats per a reunions en persona, proporcionant una excel·lent oportunitat per millorar la nostra comunicació"
            ],
            fr: [
              "Si vous êtes une entreprise andorrane, les contrats B2B entre entreprises nous offrent un faible taux d'imposition, évitant les taxes internationales ou même les transferts de services bancaires.",
              "Partenariat Stratégique pour l'Innovation: Collaborer avec Kluger Byte signifie forger un partenariat stratégique pour l'innovation. L'expertise de notre équipe et notre approche visionnaire peuvent vous aider à idéer, développer et mettre en œuvre des solutions de pointe qui stimulent la croissance de votre entreprise, élèvent la compétitivité et assurent une position plus forte sur le marché.",
              "En tant que résidents ici, nous sommes commodément situés pour des réunions en personne, offrant une excellente opportunité pour améliorer notre communication"
            ],
            de: [
              "Wenn Sie ein andorranisches Unternehmen sind, bieten B2B-Verträge zwischen Unternehmen einen niedrigen Steuersatz, um internationale Steuern zu vermeiden oder sogar Bankdienstleistungsüberweisungen.",
              "Strategische Innovationspartnerschaft: Die Zusammenarbeit mit Kluger Byte bedeutet die Schaffung einer strategischen Innovationspartnerschaft. Die Expertise unseres Teams und unser zukunftsorientierter Ansatz können Ihnen helfen, innovative Lösungen zu entwickeln und umzusetzen, die das Wachstum Ihres Unternehmens vorantreiben, die Wettbewerbsfähigkeit erhöhen und eine stärkere Marktposition sichern.",
              "Als Bewohner hier sind wir bequem für persönliche Treffen vor Ort, was eine hervorragende Gelegenheit bietet, unsere Kommunikation zu verbessern"
            ],
            ee: [
              "Kui olete Andorra ettevõte, pakuvad ettevõtetevahelised B2B lepingud meile madalat maksuprotsenti, vältides rahvusvahelisi makse või isegi pankade teenuste ülekandeid.",
              "Strateegiline Innovatsioonipartnerlus: Koostöö Kluger Byte'iga tähendab strateegilise innovatsioonipartnerluse loomist. Meie meeskonna ekspertiis ja tulevikku suunatud lähenemisviis võivad aidata teil ideid genereerida, arendada ja rakendada tipptasemel lahendusi, mis soodustavad teie ettevõtte kasvu, tõstavad konkurentsivõimet ja tagavad tugevama turupositsiooni.",
              "Kui elame siin, oleme mugavalt paiknenud isiklikke kohtumisi silmas pidades, mis pakub suurepärast võimalust meie suhtluse parandamiseks"
            ]
          },
          advantagesIcons: ["attach_money", "trending_up", "handshake"]
        },
        {
          id: "estonia",
          globalName: {
            en: "Estonia",
            es: "Estonia",
            ca: "Estònia",
            fr: "Estonie",
            de: "Estland",
            ee: "Eesti"
          },
          description: {
            en: "Benefits of Estonia",
            es: "Beneficios de Estonia",
            ca: "Beneficis d'Estonia",
            fr: "Avantages de l'Estonie",
            de: "Vorteile von Estland",
            ee: "Eesti eelised"
          },
          slogan: {
            en: "Launching from Estonia to Conquer the Global Frontier",
            es: "Despegando desde Estonia para conquistar la frontera global",
            ca: "Llançant-se des d'Estònia per conquerir la frontera global",
            fr: "Décollage depuis l'Estonie pour conquérir la frontière mondiale",
            de: "Starten Sie von Estland aus, um die globale Grenze zu erobern",
            ee: "Startides Eestist, et vallutada maailma piir"
          },
          advantages: {
            en: [
              "Zero VAT Advantage in Europe: By choosing Kluger Byte, an Estonian company, you unlock the remarkable benefit of zero VAT (Value-Added Tax) in Europe. This advantage holds the potential to significantly enhance your project's cost-effectiveness and financial strategy, enabling more strategic allocation of resources for optimal outcomes.",
              "Estonia: A Nexus of Innovation, VAT Efficiency, and Entrepreneurial Zeal. Collaborate with Estonian companies to harness a thriving innovation hub, where pioneering technologies and forward-thinking professionals converge. Benefit from Estonia's advantageous 20% VAT rate and business-friendly tax policies, optimizing your project's financial strategy for maximum impact. Immerse yourself in an entrepreneurial culture that thrives on innovation, adaptability, and unwavering determination, infusing your projects with fresh perspectives and dynamic problem-solving. Elevate your ventures with Estonia's unique blend of innovation, VAT efficiency, and entrepreneurial fervor, propelling your success to unprecedented heights.",
              "Agile Collaboration and Effective Communication: At Kluger Byte, we take pride in our commitment to agile collaboration and efficient communication, backed by our English proficiency. We prioritize clear and effective communication, keeping you engaged and informed throughout the project lifecycle for a productive and transparent partnership."
            ],
            es: [
              "Ventaja del IVA Cero en Europa: Al elegir a Kluger Byte, una empresa estonia, desbloqueas el destacado beneficio de un IVA (Impuesto sobre el Valor Añadido) cero en Europa. Esta ventaja tiene el potencial de mejorar significativamente la eficiencia de costos de tu proyecto y la estrategia financiera, permitiendo una asignación más estratégica de recursos para obtener resultados óptimos.",
              "Estonia: Pionera en Innovación, Optimización del IVA y Fomento del Emprendimiento. Colabora con empresas estonias para acceder a un vibrante centro de innovación, aprovechando una tasa estratégica del IVA del 20% y políticas fiscales para la optimización financiera. Sumérgete en una cultura emprendedora de innovación, adaptabilidad y determinación firme, inyectando proyectos con soluciones dinámicas. Eleva tus esfuerzos con la distintiva fusión de innovación, eficiencia del IVA y pasión emprendedora de Estonia, impulsando un éxito sin igual.",
              "Ágil Colaboración y Comunicación Efectiva: En Kluger Byte, nos enorgullece nuestro compromiso con la colaboración ágil y la comunicación eficaz, respaldados por nuestra destreza en inglés. Priorizamos la comunicación clara y efectiva, manteniéndote comprometido e informado durante todo el ciclo del proyecto para una asociación productiva y transparente."
            ],
            ca: [
              "Avantatge del IVA Zero a Europa: En triar Kluger Byte, una empresa estoniana, desbloqueges el destacat avantatge d'un IVA (Impost sobre el Valor Afegit) zero a Europa. Aquest avantatge té el potencial de millorar significativament l'eficiència de costos del teu projecte i l'estratègia financera, permetent una assignació més estratègica de recursos per obtenir resultats òptims.",
              "Estònia: Pionera en Innovació, Optimització de l'IVA i Foment de l'Emprenedoria. Col·labora amb empreses estonianes per accedir a un vibrant centre d'innovació, aprofitant una taxa estratègica de l'IVA del 20% i polítiques fiscals per a l'optimització financera. Submergeix-te en una cultura emprenedora d'innovació, adaptabilitat i determinació ferma, injectant projectes amb solucions dinàmiques. Eleva els teus esforços amb la distintiva fusió d'innovació, eficiència de l'IVA i passió emprenedora d'Estònia, impulsant un èxit sense igual.",
              "Col·laboració Àgil i Comunicació Efectiva: A Kluger Byte, ens enorgullim del nostre compromís amb la col·laboració àgil i la comunicació eficaç, recolzats per la nostra destresa en anglès. Prioritzem la comunicació clara i efectiva, mantenint-te compromès i informat durant tot el cicle del projecte per a una associació productiva i transparent."
            ],
            fr: [
              "Avantage de la TVA zéro en Europe: En choisissant Kluger Byte, une entreprise estonienne, vous débloquez le remarquable avantage de la TVA zéro en Europe. Cet avantage a le potentiel d'améliorer considérablement l'efficacité des coûts de votre projet et la stratégie financière, permettant une allocation plus stratégique des ressources pour des résultats optimaux.",
              "Estonie : Pionnière en matière d'innovation, d'optimisation de la TVA et de promotion de l'esprit d'entreprise. Collaborez avec des entreprises estoniennes pour exploiter un pôle d'innovation florissant, où les technologies de pointe et les professionnels novateurs convergent. Bénéficiez du taux avantageux de TVA de 20 % en Estonie et des politiques fiscales favorables aux entreprises, optimisant la stratégie financière de votre projet pour un impact maximal. Plongez dans une culture entrepreneuriale axée sur l'innovation, l'adaptabilité et une détermination inébranlable, insufflant à vos projets des perspectives fraîches et une résolution dynamique des problèmes. Élevez vos entreprises grâce au mélange unique d'innovation, d'efficacité de la TVA et de ferveur entrepreneuriale de l'Estonie, propulsant votre succès à des sommets sans précédent.",
              "Collaboration Agile et Communication Efficace : Chez Kluger Byte, nous sommes fiers de notre engagement envers la collaboration agile et la communication efficace, soutenus par notre maîtrise de l'anglais. Nous accordons la priorité à une communication claire et efficace, en vous tenant engagé et informé tout au long du cycle de vie du projet pour un partenariat productif et transparent."
            ],
            de: [
              "Vorteil der Null-Mehrwertsteuer in Europa: Wenn Sie sich für Kluger Byte, ein estnisches Unternehmen, entscheiden, schalten Sie den bemerkenswerten Vorteil der Null-Mehrwertsteuer (Value-Added Tax) in Europa frei. Dieser Vorteil hat das Potenzial, die Kosteneffizienz Ihres Projekts erheblich zu verbessern und die finanzielle Strategie zu optimieren, indem Ressourcen strategischer für optimale Ergebnisse verteilt werden.",
              "Estland: Pioniergeist in Innovation, Mehrwertsteueroptimierung und unternehmerische Begeisterung. Arbeiten Sie mit estnischen Unternehmen zusammen, um einen blühenden Innovationshub zu nutzen, in dem modernste Technologien und zukunftsorientierte Fachleute zusammenkommen. Profitieren Sie von Estlands vorteilhafter Mehrwertsteuer von 20 % und unternehmensfreundlichen Steuerpolitiken, um die finanzielle Strategie Ihres Projekts für maximale Wirkung zu optimieren. Tauchen Sie ein in eine unternehmerische Kultur, die von Innovation, Anpassungsfähigkeit und unerschütterlichem Engagement geprägt ist, und verleihen Sie Ihren Projekten frische Perspektiven und dynamische Problemlösungen. Heben Sie Ihre Vorhaben mit Estlands einzigartiger Kombination aus Innovation, Mehrwertsteuereffizienz und unternehmerischer Begeisterung auf ein unübertroffenes Niveau und treiben Sie Ihren Erfolg zu ungeahnten Höhen.",
              "Agile Zusammenarbeit und effektive Kommunikation: Bei Kluger Byte sind wir stolz auf unser Engagement für agile Zusammenarbeit und effiziente Kommunikation, unterstützt durch unsere Englischkenntnisse. Wir legen Wert auf klare und effektive Kommunikation, um Sie während des gesamten Projektzyklus engagiert und informiert zu halten und eine produktive und transparente Partnerschaft zu fördern."
            ],
            ee: [
              "Nullkäibemaksu eelis Euroopas: Valides Kluger Byte'i, Eesti ettevõtte, saate Euroopas nullkäibemaksu (Value-Added Tax) märkimisväärse eelise. See eelis võib oluliselt parandada teie projekti kulutõhusust ja finantsstrateegiat, võimaldades strateegilisemat ressursside jaotust optimaalsete tulemuste saavutamiseks.",
              "Eesti: Innovatsiooni, käibemaksu efektiivsuse ja ettevõtluse innu poolest. Töötage koostöös Eesti ettevõtetega, et kasutada ära õitsvat innovatsioonikeskkonda, kus uusimad tehnoloogiad ja tulevikku suunatud spetsialistid kokku saavad. Kasu saamiseks Eesti soodsast 20% käibemaksumäärast ja ettevõttesõbralikest maksupoliitikatest, mis optimeerivad teie projekti finantsstrateegiat maksimaalse mõju saavutamiseks. Sukelduge ettevõtluskultuuri, mis põhineb innovatsioonil, kohandumisel ja kindlal pühendumisel, süstides oma projektidesse värskeid vaatenurki ja dünaamilisi probleemilahendusi. Tõstke oma ettevõtmised Eesti ainulaadsest kombinatsioonist innovatsioonist, käibemaksu efektiivsusest ja ettevõtlusindu, viies oma edu enneolematutele kõrgustele.",
              "Agile koostöö ja tõhus kommunikatsioon: Kluger Byte'is oleme uhked meie pühendumuse üle agiilsele koostööle ja tõhusale kommunikatsioonile, mida toetavad meie inglise keele oskused. Me panustame selgesse ja tõhusasse kommunikatsiooni, hoides teid projekti elutsükli jooksul kaasatuna ja teavitatuna, et tagada produktiivne ja läbipaistev partnerlus."
            ]
          },
          advantagesIcons: ["attach_money", "cached", "view_kanban"]
        }
    ],

    personal: {
        name: 'Franco Lobos',
        age: "01-19-1996",
        role: {
          en: "Founder",
          es: "Fundador",
          ca: "Fundador",
          fr: "Fondateur",
          de: "Gründer",
          ee: "Asutaja"
        },
        description: {
          en: "Kluger Byte is my latest project, through which I aim to contribute to various digital initiatives within companies worldwide.",
          es: "Kluger Byte es mi proyecto más reciente con el que busco ofrecer mi participación en diferentes iniciativas digitales en empresas de todo el mundo.",
          ca: "Kluger Byte és el meu últim projecte, a través del qual aspiro a contribuir en diverses iniciatives digitals dins d'empreses de tot el món.",
          fr: "Kluger Byte est mon dernier projet, à travers lequel j'aspire à contribuer à diverses initiatives numériques au sein d'entreprises du monde entier.",
          de: "Kluger Byte ist mein neuestes Projekt, durch das ich dazu beitragen möchte, verschiedene digitale Initiativen in Unternehmen weltweit zu unterstützen.",
          ee: "Kluger Byte on minu viimane projekt, mille kaudu kavatsen panustada erinevatesse digitaalsetesse algatustesse ülemaailmsetes ettevõtetes."
        },
        button: {
          en: "About Me",
          es: "Sobre mí",
          ca: "Sobre meu",
          fr: "À propos de moi",
          de: "Über mich",
          ee: "Minust"
        }
    },

    certifications:[
        {name: "React", path: "react"},
        {name: "Remix", path: "remix"},
        {name: "Node.Js", path: "node"},
        {name: "Express.Js", path: "express"},
        {name: "MongoDB", path: "mongo"},

        {name: "Apache Cordova", path: "apache"},
        {name: "p5.Js", path: "p5"},

        {name: "Javascript", path: "js"},
        {name: "HTML5", path: "html"},
        {name: "CSS3", path: "css"},

        {name: "PHP", path: "php"},
        {name: "WordPress", path: "wp"},
        {name: "AJAX", path: "ajax"},
        {name: "Boostrap", path: "boostrap"},

        {name: "Python", path: "py"},
        {name: "Vs Code", path: "vs"},
        {name: "Github", path: "git"}
    ],

    contact: {
        title: {
          en: "Tell me about your project",
          es: "Cuéntame sobre tu proyecto",
          ca: "Expliqueu-me el vostre projecte",
          fr: "Parlez-moi de votre projet",
          de: "Erzählen Sie mir von Ihrem Projekt",
          ee: "Räägi mulle oma projektist"
        },
        paragraph: {
          en: "I will personally study your proposal, evaluating every aspect of your company and its project. Simply get in touch, and I will become a part of your team!",
          es: "Estudiaré tu propuesta personalmente, evaluando cada aspecto de tu empresa y su proyecto. ¡Simplemente ponte en contacto y formaré parte de tu equipo!",
          ca: "Estudiaré la vostra proposta personalment, avaluant cada aspecte de la vostra empresa i el seu projecte. Simplement poseu-vos en contacte i formaré part del vostre equip!",
          fr: "J'étudierai personnellement votre proposition, évaluant chaque aspect de votre entreprise et de son projet. Il vous suffit de me contacter, et je ferai partie de votre équipe !",
          de: "Ich werde Ihren Vorschlag persönlich prüfen und jeden Aspekt Ihres Unternehmens und Ihres Projekts bewerten. Kontaktieren Sie mich einfach, und ich werde Teil Ihres Teams!",
          ee: "Uurin isiklikult teie ettepanekut, hinnates teie ettevõtte ja selle projekti igat aspekti. Lihtsalt võtke ühendust ja ma saan teie meeskonna osaks!"
        },
        button: {
          en: "Let's talk!",
          es: "Hablemos",
          ca: "Parlem!",
          fr: "Parlons-en !",
          de: "Lass uns sprechen!",
          ee: "Räägime!"
        }
      },

   links: {
        home: {
            en: 'Home',
            es: 'Inicio',
            ca: 'Inici',
            fr: 'Accueil',
            de: 'Startseite',
            ee: 'Kodu'
        },
        contact: {
            en: 'Contact',
            es: 'Contactar',
            ca: 'Contacte',
            fr: 'Contact',
            de: 'Kontakt',
            ee: 'Kontakt'
        },
        email: {
            en: 'Send Email',
            es: 'Enviar Correo',
            ca: 'Envia un correu',
            fr: 'Envoyer un email',
            de: 'E-Mail senden',
            ee: 'Saada e-kiri'
        }
    }

}


export const contactData = {
    sectionTitle: {
        en: "Contact",
        es: "Contactar",
        ca: "Contacte",
        fr: "Contact",
        de: "Kontakt",
        ee: "Kontakt"
      },
      form: {
        required: {
          en: 'Required',
          es: 'Requerido',
          ca: 'Requerit',
          fr: 'Obligatoire',
          de: 'Erforderlich',
          ee: 'Nõutud'
        },
        name: {
          label: {
            en: "Full Name",
            es: "Nombre Completo",
            ca: "Nom complet",
            fr: "Nom complet",
            de: "Vollständiger Name",
            ee: "Täisnimi"
          },
          error: {
            en: "",
            es: "",
            ca: "",
            fr: "",
            de: "",
            ee: ""
          }
        },
        company: {
          label: {
            en: "Company Name",
            es: "Nombre de la empresa",
            ca: "Nom de l'empresa",
            fr: "Nom de l'entreprise",
            de: "Firmenname",
            ee: "Ettevõtte nimi"
          },
          error: {
            en: "",
            es: "",
            ca: "",
            fr: "",
            de: "",
            ee: ""
          }
        },
        email: {
          label: {
            en: "Email",
            es: "Correo electrónico",
            ca: "Correu electrònic",
            fr: "E-mail",
            de: "E-Mail",
            ee: "E-post"
          },
          error: {
            en: "Invalid e-mail address",
            es: "Dirección de correo inválida",
            ca: "Adreça de correu electrònic no vàlida",
            fr: "Adresse e-mail invalide",
            de: "Ungültige E-Mail-Adresse",
            ee: "Vigane e-posti aadress"
          }
        },
        enquiry: {
          name: {
            en: "Type of project",
            es: "Tipo de Proyecto",
            ca: "Tipus de projecte",
            fr: "Type de projet",
            de: "Art des Projekts",
            ee: "Projekti tüüp"
          },
          placeHolder: {
            en: "What kind of project is it about?",
            es: "De qué tipo de proyecto se trata?",
            ca: "De quin tipus de projecte es tracta?",
            fr: "De quel type de projet s'agit-il?",
            de: "Um was für ein Projekt handelt es sich?",
            ee: "Millise projekti kohta on tegemist?"
          },
          error: {
            en: 'You should definitely Hire me',
            es: 'Definitivamente debes contratarme',
            ca: 'Sens dubte hauries de contractar-me',
            fr: 'Vous devriez certainement m\'embaucher',
            de: 'Du solltest mich definitiv einstellen',
            ee: 'Kindlasti peaks mind palgama'
          },
          options: [
            {
              key:{
                "en": "Meeting for a personalized budget",
                "es": "Reunión para un presupuesto personalizado",
                "ca": "Reunió per a un pressupost personalitzat",
                "fr": "Réunion pour un budget personnalisé",
                "de": "Besprechung für ein personalisiertes Budget",
                "ee": "Kohtumine isikupärase eelarve jaoks"
              },
              value: "personalizedBudget"
            },
            {
              key: {
                en: "Long term project proposal",
                es: "Propuesta de proyecto a largo plazo",
                ca: "Proposta de projecte a llarg termini",
                fr: "Proposition de projet à long terme",
                de: "Langfristiger Projektvorschlag",
                ee: "Pikaajaline projekti ettepanek"
              },
              value: "hireMe"
            },
            {
              key: {
                en: "React Project collaboration",
                es: "Colaboración en proyecto de React",
                ca: "Col·laboració en projecte de React",
                fr: "Collaboration sur le projet React",
                de: "Zusammenarbeit bei React-Projekten",
                ee: "Koostöö Reacti projekti raames"
              },
              value: "reactProject"
            },
            {
              key: {
                en: "WordPress Project collaboration",
                es: "Colaboración en proyecto de WordPress",
                ca: "Col·laboració en projecte de WordPress",
                fr: "Collaboration sur le projet WordPress",
                de: "Zusammenarbeit bei WordPress-Projekten",
                ee: "Koostöö WordPressi projekti raames"
              },
              value: "wpProject"
            },
            {
              key: {
                en: "Other",
                es: "Otro",
                ca: "Altres",
                fr: "Autre",
                de: "Andere",
                ee: "Muu"
              },
              value: "other"
            }
          ]
        },
        coment: {
          label: {
            en: "Introduce me your project",
            es: "Cuéntame sobre tu proyecto",
            ca: "Presenta'm el teu projecte",
            fr: "Présentez-moi votre projet",
            de: "Stellen Sie mir Ihr Projekt vor",
            ee: "Rääkige mulle oma projektist"
          },
          error: {
            en: "Must be at least 25 characters",
            es: "Debe tener al menos 25 caracteres",
            ca: "Ha de tenir com a mínim 25 caràcters",
            fr: "Doit comporter au moins 25 caractères",
            de: "Muss mindestens 25 Zeichen lang sein",
            ee: "Peab olema vähemalt 25 tähemärki pikk"
          }
        },
        button: {
          submit: {
            en: "Send",
            es: "Enviar",
            ca: "Enviar",
            fr: "Envoyer",
            de: "Senden",
            ee: "Saada"
          },
          wait: {
            en: "Loading...",
            es: "Cargando...",
            ca: "Carregant...",
            fr: "Chargement...",
            de: "Laden...",
            ee: "Laadimine..."
          }
        }
      }
}