import CompanyCard from "./CompanyCard";

import { HomeData } from "../../constants/homeConst";

const Company = () =>{

    const countries = HomeData.countries;

    return(
        <section className="company-section">
            <div className="company-line"></div>
            <div className="company-cards-main">
            {
                countries.map((country)=>
                    <CompanyCard country={country} key={country.id}></CompanyCard>
                )
            }
            </div>
        </section>
    )
}

export default Company;