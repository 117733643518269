import {useState} from "react";
import emailjs from '@emailjs/browser';

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */

const jsCredentials ={
  service : "service_3q1rojr",
  template: "template_vs747wc",
  publicKey: '62NZe3wS7IAfn_Xpz'
}
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (data) => {
    setLoading(true);
    emailjs.send(
      jsCredentials.service,
      jsCredentials.template,{
      from_name: data.firstName,
      to_name: "Franco",
      message: data.comment,
      reply_to: data.email,
      from_company: data.company,
      from_enquiry: data.type,
    },
    jsCredentials.publicKey
    ).then(res=>{
      // console.log(res.text);
      setResponse({
        type: 'success',
        message: `Thanks for your submission ${data.firstName}, we will get back to you shortly!`,
      })
    }, error => {
      setResponse({
        type: 'error',
        message: error.text,
      })
    });
    setLoading(false);
  };

  return { isLoading, response, submit };
}

export default useSubmit;
