const Separator = ({text, classes=""})=>{

    return(
        <section className={`separator ${classes}`}>
            <h1 className="separator-text">
                {text}
            </h1>
        </section>
    )
}

export default Separator;