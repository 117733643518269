import Company from "./Company";
import Hero from "./Hero";
import Personal from "./Personal";
import Certifications from "./Certifications";
import Separator from "../template/Separator";
import Contact from "./Contact";

import { HomeData } from "../../constants/homeConst";
import { useTheme } from "../ThemeContext";

const HomePage = ()=>{

    const {lang} = useTheme();
    return(
        <>
            <Hero></Hero>

            <Separator text={HomeData.sections.where[lang]}></Separator>
            <Company></Company>

            <Separator text={HomeData.sections.who[lang]} classes={'right'}></Separator>
            <Personal></Personal>

            <Separator text={HomeData.sections.why[lang]}></Separator>
            <Certifications></Certifications>

            <Separator text={HomeData.sections.ready[lang]}  classes={'center'}></Separator>
            <Contact></Contact>
        </>
    )
}

export default HomePage;