import MaterialIconsReact from "material-icons-react";
import { useTheme } from "../ThemeContext";
import { useState } from "react";

const CompanyCard = ({country}) =>{

    const {lang} = useTheme();


    const [selected, setSelected] = useState(0);


    return(
        <div className="company-card" id={country.id}>
            <h1>{country.globalName[lang]}</h1>
            <h2>{country.slogan[lang]}</h2>


            <div id="company-icons-selector">
                {
                country.advantagesIcons.map((icon,indx)=>
                <div key={icon} className={selected===indx ? 'company-icon active':'company-icon'} onClick={()=>setSelected(indx)}>
                    <MaterialIconsReact icon={icon} color={'inherit'}/>
                </div>
                )
                }
            </div>
            <p className="company-parragraph">{country.advantages[lang][selected]}</p>

        </div>
    )
}

export default CompanyCard;