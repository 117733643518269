

const CertificationsCardsItem = ({card, setFocus, unsetFocus })=>{

    const cardStyle = {
        backgroundImage: `url(${require(`../../assets/stack/${card.path}.png`)})`,
    }

    return(
        <div className="certification-card" onMouseEnter={()=>{setFocus(card.name)}} onMouseLeave={unsetFocus}>
            <div className="card-image" style={cardStyle}>
            </div>
            {/* <h3 className="card-text">{card.name}</h3> */}
        </div>
    );
}

export default CertificationsCardsItem;