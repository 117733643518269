export const ColorsConst = {
    "deep-blue": {
      light: "#569cd6",
      dark: "#356492"
    },
    "silver-gray": {
      light: "#d4d4d4",
      dark: "#4d4d4d"
    },
    "golden-yellow": {
      light: "#ce9178",
      dark: "#8e6238"
    },
    "emerald-green": {
      light: "#dcdcaa",
      dark: "#6d846d"
    },
    "intense-violet": {
      light: "#c586c0",
      dark: "#7a4c7a"
    },
    "bg-color": {
      light: "#1e1e1e", //1e1e1e
      dark: "#f0f0f0" //d4d4d5
    }
}